import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "animating-with-react-redux-and-d3"
    }}>{`Animating with React, Redux, and d3`}</h1>
    <Vimeo id={440705509} mdxType="Vimeo" />
    <p>{`And now for some pure nerdy fun: A particle generator… or, well, as close as
you can get with React and D3. You'd need WebGL for a `}<em parentName="p">{`real`}</em>{` particle
generator.`}</p>
    <p>{`We're making tiny circles fly out of your mouse cursor. Works on mobile with
your finger, too.`}</p>
    <p>{`To see the particle generator in full action,
`}<a parentName="p" {...{
        "href": "https://swizec.github.io/react-particles-experiment/"
      }}>{`go here`}</a>{`. Embedded below for your convenience. Github won't let me host different branches, so you'll see the advanced 20,000 particle version from next chapter.`}</p>
    <iframe src="https://swizec.github.io/react-particles-experiment/" style={{
      width: "100%",
      border: 0,
      height: "400px"
    }} />
    <p>{`We're using the
`}<a parentName="p" {...{
        "href": "/ball-game/1"
      }}>{`game loop`}</a>{`
approach to animation and Redux to store the state tree and drive changes for
each frame.`}</p>
    <p>{`You can see the full code
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-particles-experiment"
      }}>{`on GitHub`}</a>{`. I've merged
the SVG and Canvas branches. Redux part is the same, some parameters differ.
We're focusing on the Redux part because that's what's new.`}</p>
    <p>{`It's going to be great.`}</p>
    <p><em parentName="p">{`Code in this example uses the `}<inlineCode parentName="em">{`.jsx`}</inlineCode>{` file extension. I originally wrote
it back when that was still a thing, and while I did update everything to React
16+, I felt that changing filenames was unnecessary.`}</em></p>
    <h2 {...{
      "id": "heres-how-it-works"
    }}>{`Here's how it works`}</h2>
    <p>{`We use `}<strong parentName="p">{`React to render everything`}</strong>{`: the page, the SVG element, the particles
inside. This lets us tap into React's algorithms that decide which nodes to
update and when to garbage collect old nodes.`}</p>
    <p>{`Then we use some `}<strong parentName="p">{`d3 calculations and event detection`}</strong>{`. D3 has great random
generators, so we take advantage of that. D3's mouse and touch event handlers
calculate coordinates relative to our SVG. We need those, but React's click
handlers are based on DOM nodes, which don't correspond to `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{`
coordinates. D3 looks at real cursor position on screen.`}</p>
    <p>{`All `}<strong parentName="p">{`particle coordinates are in a Redux store`}</strong>{`. Each particle also has a
movement vector. The store holds some useful flags and general parameters, as
well. This lets us treat animation as data transformations. I'll show you what
that means.`}</p>
    <p>{`We use `}<strong parentName="p">{`actions to communicate user events`}</strong>{` like creating particles, starting
the animation, changing mouse position, and so on. On each
requestAnimationFrame, we `}<strong parentName="p">{`dispatch an "advance animation" action`}</strong>{`.`}</p>
    <p>{`On each action, the `}<strong parentName="p">{`reducer calculates a new state`}</strong>{` for the whole app. This
includes `}<strong parentName="p">{`new particle positions`}</strong>{` for each step of the animation.`}</p>
    <p>{`When the store updates, `}<strong parentName="p">{`React flushes changes`}</strong>{` via props and because
`}<strong parentName="p">{`coordinates are state, the particles move`}</strong>{`.`}</p>
    <p>{`The result is smooth animation. Just like the game loop example from before.`}</p>
    <h2 {...{
      "id": "some-basic-terminology"
    }}>{`Some basic terminology`}</h2>
    <p>{`We're about to throw around some terms. You'll understand what they mean in
detail after this chapter.`}</p>
    <p>{`Until then, here's a quick glossary so you don't feel lost:`}</p>
    <p><strong parentName="p">{`The store, or the state`}</strong>{`, is Redux state. Held globally and shared by all
components. It's like the App local state from earlier chapters.`}</p>
    <p><strong parentName="p">{`Actions`}</strong>{` are packets of information that tell us what happened.`}</p>
    <p><strong parentName="p">{`Reducers`}</strong>{` are functions that take the current state and an action, and use
that information to generate a new state.`}</p>
    <p>{`Got it? No worries. You will soon 😃`}</p>
    <h2 {...{
      "id": "3-presentation-components"
    }}>{`3 presentation components`}</h2>
    <p>{`We start with the presentation components because they're the simplest. To
render a collection of particles, we need:`}</p>
    <ul>
      <li parentName="ul">{`a stateless `}<inlineCode parentName="li">{`Particle`}</inlineCode></li>
      <li parentName="ul">{`a stateless `}<inlineCode parentName="li">{`Particles`}</inlineCode></li>
      <li parentName="ul">{`a class-based `}<inlineCode parentName="li">{`App`}</inlineCode></li>
    </ul>
    <p>{`None of them contain state, but `}<inlineCode parentName="p">{`App`}</inlineCode>{` has to be a class-based component so that
we can use `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`. We need it to attach D3 event listeners.`}</p>
    <h3 {...{
      "id": "particle"
    }}>{`Particle`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Particle`}</inlineCode>{` component is a circle. It looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles/Particle.jsx
import React from "react"

const Particle = ({ x, y }) => <circle cx={x} cy={y} r="1.8" />

export default Particle
`}</code></pre>
    <p>{`It takes `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinates and returns an SVG circle.`}</p>
    <h3 {...{
      "id": "particles"
    }}>{`Particles`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Particles`}</inlineCode>{` component isn't much smarter – it returns a list of circles
wrapped in a grouping element, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles/index.jsx
import React from "react"
import Particle from "./Particle"

const Particles = ({ particles }) => (
  <g>
    {particles.map((particle) => (
      <Particle key={particle.id} {...particle} />
    ))}
  </g>
)

export default Particles
`}</code></pre>
    <p>{`Walk through the array of particles, render a Particle component for each.
Declarative rendering that you've seen before 😃`}</p>
    <p>{`We can take an array of `}<inlineCode parentName="p">{`{id, x, y}`}</inlineCode>{` objects and render SVG circles. Now comes
our first fun component: the `}<inlineCode parentName="p">{`App`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "app"
    }}>{`App`}</h3>
    <p><inlineCode parentName="p">{`App`}</inlineCode>{` takes care of rendering the scene and attaching d3 event listeners. It
gets actions via props and ties them to mouse events. You can think of actions
as callbacks that work on the global data store directly. No need to pass them
through many levels of props.`}</p>
    <p>{`The rendering part looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/index.jsx

import React, { Component } from "react"
import { select as d3Select, mouse as d3Mouse, touches as d3Touches } from "d3"

import Particles from "./Particles"
import Footer from "./Footer"
import Header from "./Header"

class App extends Component {
  // ..
  render() {
    return (
      <div
        onMouseDown={(e) => this.props.startTicker()}
        style={{ overflow: "hidden" }}
      >
        <Header />
        <svg
          width={this.props.svgWidth}
          height={this.props.svgHeight}
          ref="svg"
          style={{ background: "rgba(124, 224, 249, .3)" }}
        >
          <Particles particles={this.props.particles} />
        </svg>
        <Footer N={this.props.particles.length} />
      </div>
    )
  }
}

export default App
`}</code></pre>
    <p>{`There's more going on, but the gist is that we return a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` with a
`}<inlineCode parentName="p">{`Header`}</inlineCode>{`, a `}<inlineCode parentName="p">{`Footer`}</inlineCode>{`, and an `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{`. Inside `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{`, we use `}<inlineCode parentName="p">{`Particles`}</inlineCode>{` to
render many circles. The Header and Footer components are just some helpful
text.`}</p>
    <p>{`Notice that the core of our rendering function only says `}<em parentName="p">{`"Put all Particles
here, please"`}</em>{`. There's nothing about what's moved, what's new, or what's no
longer needed. We don’t have to worry about that.`}</p>
    <p>{`We get a list of coordinates and naively render some circles. React takes care
of the rest.`}</p>
    <p>{`Oh, and we call `}<inlineCode parentName="p">{`startTicker()`}</inlineCode>{` when a user clicks on our scene. No reason to
have the clock running `}<em parentName="p">{`before`}</em>{` any particles exist.`}</p>
    <h4 {...{
      "id": "d3-event-listeners"
    }}>{`D3 event listeners`}</h4>
    <p>{`To let users generate particles, we have to wire up some functions in
`}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`. That looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/index.jsx

class App extends Component {
    svgWrap = React.createRef();

    componentDidMount() {
        let svg = d3Select(this.svgWrap.current);

        svg.on('mousedown', () => {
            this.updateMousePos();
            this.props.startParticles();
        });
        svg.on('touchstart', () => {
            this.updateTouchPos();
            this.props.startParticles();
        });
        svg.on('mousemove', () => {
            this.updateMousePos();
        });
        svg.on('touchmove', () => {
            this.updateTouchPos();
        });
        svg.on('mouseup', () => {
            this.props.stopParticles();
        });
        svg.on('touchend', () => {
            this.props.stopParticles();
        });
        svg.on('mouseleave', () => {
            this.props.stopParticles();
        });
    }

    updateMousePos() {
        let [x, y] = d3Mouse(this.svgWrap.current);
        this.props.updateMousePos(x, y);
    }

    updateTouchPos() {
        let [x, y] = d3Touches(this.svgWrap.current)[0];
        this.props.updateMousePos(x, y);
    }
`}</code></pre>
    <p>{`There are several events we take into account:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`mousedown`}</inlineCode>{` and `}<inlineCode parentName="li">{`touchstart`}</inlineCode>{` turn on particle generation`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mousemove`}</inlineCode>{` and `}<inlineCode parentName="li">{`touchmove`}</inlineCode>{` update the mouse location`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mouseup`}</inlineCode>{`, `}<inlineCode parentName="li">{`touchend`}</inlineCode>{`, and `}<inlineCode parentName="li">{`mouseleave`}</inlineCode>{` turn off particle generation`}</li>
    </ul>
    <p>{`Inside our event callbacks, we use `}<inlineCode parentName="p">{`updateMousePos`}</inlineCode>{` and `}<inlineCode parentName="p">{`updateTouchPos`}</inlineCode>{` to
update Redux state. They use `}<inlineCode parentName="p">{`d3Mouse`}</inlineCode>{` and `}<inlineCode parentName="p">{`d3Touches`}</inlineCode>{` to get `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{`
coordinates for new particles relative to our SVG element and call Redux
actions passed-in via props. The particle generation step uses this data as
each particle's initial position.`}</p>
    <p>{`You'll see that in the next section. I agree, it smells kind of convoluted, but
it's for good reason: We need a reference to a mouse event to get the cursor
position, and we want to decouple particle generation from event handling.`}</p>
    <p>{`Remember, React isn't smart enough to figure out mouse position relative to our
drawing area. React knows that we clicked a DOM node.
`}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-selection/blob/master/src/mouse.js"
      }}>{`D3 does some magic`}</a>{`
to find exact coordinates.`}</p>
    <p>{`Touch events return lists of coordinates. One for each finger. We use only the
first coordinate because shooting particles out of multiple fingers would make
this example too hard.`}</p>
    <p>{`That's it for rendering and user events.
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-particles-experiment/blob/svg-based-branch/src/components/index.jsx"
      }}>{`107 lines of code`}</a>{`.`}</p>
    <h2 {...{
      "id": "6-redux-actions"
    }}>{`6 Redux Actions`}</h2>
    <p>{`Redux actions are a fancy way of saying `}<em parentName="p">{`"Yo, a thing happened!"`}</em>{`. They're
functions you call to get structured metadata that's passed into Redux
reducers.`}</p>
    <p>{`Our particle generator uses 6 actions:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`tickTime`}</inlineCode>{` steps our animation to the next frame`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`tickerStarted`}</inlineCode>{` fires when everything begins`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`startParticles`}</inlineCode>{` fires when we hold down the mouse`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`stopParticles`}</inlineCode>{` fires when we release`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`updateMousePos`}</inlineCode>{` keeps mouse position saved in state`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`resizeScreen`}</inlineCode>{` saves new screen size so we know where edges lie`}</li>
    </ol>
    <p>{`Our actions look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export function updateMousePos(x, y) {
  return {
    type: UPDATE_MOUSE_POS,
    x: x,
    y: y,
  }
}
`}</code></pre>
    <p>{`A function that accepts params and returns an object with a type and meta data.
Technically this is an action generator and the object is an action, but that
distinction has long since been lost in the community.`}</p>
    <p>{`Actions `}<em parentName="p">{`must`}</em>{` have a `}<inlineCode parentName="p">{`type`}</inlineCode>{`. Reducers use the type to decide what to do. The
rest is optional.`}</p>
    <p>{`You can see
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-particles-experiment/blob/master/src/actions/index.js"
      }}>{`all the actions on GitHub`}</a>{`.`}</p>
    <p>{`I find this to be the least elegant part of Redux. Makes sense in large
applications, but way too convoluted for small apps. Simpler alternatives exist
like doing it yourself with React Context.`}</p>
    <h2 {...{
      "id": "1-container-component"
    }}>{`1 Container component`}</h2>
    <p>{`Containers are React components that talk to the Redux data store.`}</p>
    <p>{`You can think of presentation components as templates that render stuff and
containers as smart-ish views that talk to controllers. Or maybe they're the
controllers.`}</p>
    <p>{`Sometimes it's hard to tell. In theory presentation components render and don't
think, containers communicate and don't render. Redux reducers and actions do
the thinking.`}</p>
    <p>{`I'm not sure this separation is necessary in small projects.`}</p>
    <p>{`Maintaining it can be awkward and sometimes cumbersome in mid-size projects,
but I'm sure it makes total sense at Facebook scale. We're using it in this
project because the community has decided that's the way to go.`}</p>
    <p>{`We use the idiomatic `}<inlineCode parentName="p">{`connect()`}</inlineCode>{` approach. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/containers/AppContainer.jsx

import { connect } from "react-redux"
import React, { Component } from "react"
import * as d3 from "d3"

import App from "../components"
import {
  tickTime,
  tickerStarted,
  startParticles,
  stopParticles,
  updateMousePos,
} from "../actions"

class AppContainer extends Component {
  startTicker = () => {
    const { isTickerStarted } = this.props

    if (!isTickerStarted) {
      console.log("Starting ticker")
      this.props.tickerStarted()
      d3.timer(this.props.tickTime)
    }
  }

  render() {
    const { svgWidth, svgHeight, particles } = this.props

    return (
      <App
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        particles={particles}
        startTicker={this.startTicker}
        startParticles={this.props.startParticles}
        stopParticles={this.props.stopParticles}
        updateMousePos={this.props.updateMousePos}
      />
    )
  }
}

const mapStateToProps = ({
  generateParticles,
  mousePos,
  particlesPerTick,
  isTickerStarted,
  svgWidth,
  svgHeight,
  particles,
}) => ({
  generateParticles,
  mousePos,
  particlesPerTick,
  isTickerStarted,
  svgWidth,
  svgHeight,
  particles,
})

const mapDispatchToProps = {
  tickTime,
  tickerStarted,
  startParticles,
  stopParticles,
  updateMousePos,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
`}</code></pre>
    <p>{`I love the smell of boilerplate in the morning. :nose:`}</p>
    <p>{`We import dependencies and define `}<inlineCode parentName="p">{`AppContainer`}</inlineCode>{` as a class-based React
`}<inlineCode parentName="p">{`Component`}</inlineCode>{` so we have somewhere to put the D3 interval. The render method
outputs our `}<inlineCode parentName="p">{`<App>`}</inlineCode>{` component using a bunch of props to pass relevant actions
and values.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`startTicker`}</inlineCode>{` method is a callback we pass into App. It runs on first click
and starts the D3 interval if necessary. Each interval iteration triggers the
`}<inlineCode parentName="p">{`tickTime`}</inlineCode>{` action.`}</p>
    <h3 {...{
      "id": "appcontainer-talks-to-the-store"
    }}>{`AppContainer talks to the store`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/containers/AppContainer.jsx

const mapStateToProps = ({
  generateParticles,
  mousePos,
  particlesPerTick,
  isTickerStarted,
  svgWidth,
  svgHeight,
  particles,
}) => ({
  generateParticles,
  mousePos,
  particlesPerTick,
  isTickerStarted,
  svgWidth,
  svgHeight,
  particles,
})

const mapDispatchToProps = {
  tickTime,
  tickerStarted,
  startParticles,
  stopParticles,
  updateMousePos,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
`}</code></pre>
    <p>{`We're using the `}<inlineCode parentName="p">{`connect()`}</inlineCode>{` idiom to connect our AppContainer to the Redux
store. It's a higher order component that handles all the details of connection
to the store and staying in sync.`}</p>
    <p>{`We pass two arguments into connect. This returns a higher order component
function, which we wrap around AppContainer.`}</p>
    <p>{`The first argument is `}<strong parentName="p">{`mapStateToProps`}</strong>{`. It accepts current state as an
argument, which we immediately deconstruct into interesting parts, and returns
a key:value dictionary. Each key becomes a component prop with the
corresponding value.`}</p>
    <p>{`You'd often use this opportunity to run ad-hoc calculations, or combine parts
of state into single props. No need for that in our case, just pass it through.`}</p>
    <h4 {...{
      "id": "dispatching-actions"
    }}>{`Dispatching actions`}</h4>
    <p><strong parentName="p">{`mapDispatchToProps`}</strong>{` is a dictionary that maps props to actions. Each prop
turns into an action generator wrapped in a `}<inlineCode parentName="p">{`store.dispatch()`}</inlineCode>{` call. To fire an
action inside a component we just call the function in that prop.`}</p>
    <p>{`But Swiz, we're not writing key:value dictionaries, we're just listing stuff!`}</p>
    <p>{`That's a syntax supported in most modern JavaScript environments, called object
literal property value shorthand. Our build system expands that
`}<inlineCode parentName="p">{`mapDispatchToProps`}</inlineCode>{` dictionary into something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const mapDispatchToProps = {
  tickTime: tickTime,
  tickerStarted: tickerStarted,
  startParticles: startParticles,
  stopParticles: stopParticles,
  updateMousePos: updateMousePos,
}
`}</code></pre>
    <p>{`And you thought previous code had a lot of boilerplate ... imagine if this was
how you'd do it in real life :stuck_out_tongue:`}</p>
    <p><inlineCode parentName="p">{`connect`}</inlineCode>{` wraps each of these action generators in `}<inlineCode parentName="p">{`store.dispatch()`}</inlineCode>{` calls.
You can pass the resulting function into any component and fire actions by
calling that method.`}</p>
    <h4 {...{
      "id": "the-redux-loop"
    }}>{`The Redux loop`}</h4>
    <p>{`To make a change therefore, a Redux loop unfolds:`}</p>
    <ol>
      <li parentName="ol">{`Call our action triggerer, passed in through props`}</li>
      <li parentName="ol">{`Calls the generator, gets a `}<inlineCode parentName="li">{`{type: ...}`}</inlineCode>{` object`}</li>
      <li parentName="ol">{`Dispatches that object on the store`}</li>
      <li parentName="ol">{`Redux calls the reducer`}</li>
      <li parentName="ol">{`Reducer creates new state`}</li>
      <li parentName="ol">{`Store updates triggering React's engine to flow updates through the props`}</li>
    </ol>
    <p>{`So that's the container. 71 lines of boilerplate pretty code.`}</p>
    <p>{`The remaining piece of the puzzle is our reducer. Two reducers in fact.`}</p>
    <h2 {...{
      "id": "2-redux-reducers"
    }}>{`2 Redux Reducers`}</h2>
    <p>{`With the actions firing and the drawing done, it's time to look at the business
logic of our particle generator. We'll get it done in just 33 lines of code and
some change.`}</p>
    <p>{`Well, it's a bunch of change. But the 33 lines that make up `}<inlineCode parentName="p">{`CREATE_PARTICLES`}</inlineCode>{`
and `}<inlineCode parentName="p">{`TIME_TICK`}</inlineCode>{` changes are the most interesting. The rest just flips various
flags.`}</p>
    <p>{`All our logic and physics goes in the reducer.
`}<a parentName="p" {...{
        "href": "https://redux.js.org/docs/basics/Reducers.html"
      }}>{`Dan Abramov says`}</a>{` to think of
reducers as the function you'd put in `}<inlineCode parentName="p">{`.reduce()`}</inlineCode>{`. Given a state and a set of
changes, how do I create the new state?`}</p>
    <p>{`A "sum numbers" example would look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let sum = [1, 2, 3, 4].reduce((sum, n) => sum + n, 0)
`}</code></pre>
    <p>{`For each number, take the previous sum and add the number.`}</p>
    <p>{`Our particle generator is a more advanced version of the same concept: Takes
current application state, incorporates an action, and returns new application
state.`}</p>
    <p>{`Start with a default state and some D3 random number helpers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { randomNormal } from "d3";

const Gravity = 0.5,
    randNormal = randomNormal(0.3, 2),
    randNormal2 = randomNormal(0.5, 1.:sunglasses:;

const initialState = {
    particles: [],
    particleIndex: 0,
    particlesPerTick: 30,
    svgWidth: 800,
    svgHeight: 600,
    isTickerStarted: false,
    generateParticles: false,
    mousePos: [null, null],
    lastFrameTime: null
};
`}</code></pre>
    <p>{`Using D3's `}<inlineCode parentName="p">{`randomNormal`}</inlineCode>{` random number generator creates a better random
distribution than using JavaScript's own `}<inlineCode parentName="p">{`Math.random`}</inlineCode>{`. The rest is a bunch of
default state 👇`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`particles`}</inlineCode>{` holds an array of particles to draw`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`particleIndex`}</inlineCode>{` defines the ID of the next generated particle`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`particlesPerTick`}</inlineCode>{` defines how many particles we create on each
requestAnimationFrame`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`svgWidth`}</inlineCode>{` is the width of our drawing area`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`svgHeigh`}</inlineCode>{` is the height`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`isTickerStarted`}</inlineCode>{` specifies whether the animation is running`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`generateParticles`}</inlineCode>{` turns particle generation on and off`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mousePos`}</inlineCode>{` defines the origination point for new particles`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lastFrameTime`}</inlineCode>{` helps us compensate for dropped frames`}</li>
    </ul>
    <p>{`To manipulate all this state, we use two reducers and manually combine them.
Redux does come with a `}<inlineCode parentName="p">{`combineReducers`}</inlineCode>{` function, but I wanted to keep our
state flat and that doesn't fit `}<inlineCode parentName="p">{`combineReducers`}</inlineCode>{`'s view of how life should
work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/reducers/index.js

// Manually combineReducers
export default function (state = initialState, action) {
  return {
    ...appReducer(state, action),
    ...particlesReducer(state, action),
  }
}
`}</code></pre>
    <p>{`This is our reducer. It takes current `}<inlineCode parentName="p">{`state`}</inlineCode>{`, sets it to `}<inlineCode parentName="p">{`initialState`}</inlineCode>{` if
undefined, and an action. To create new state, it spreads the object returned
from `}<inlineCode parentName="p">{`appReducer`}</inlineCode>{` and from `}<inlineCode parentName="p">{`particlesReducer`}</inlineCode>{` into a new object. You can
combine as many reducers as you want in this way.`}</p>
    <p>{`The usual `}<inlineCode parentName="p">{`combineReducers`}</inlineCode>{` approach leads to nested hierarchical state. That
often works great, but I wanted to keep our state flat.`}</p>
    <p>{`Lesson here is that there are no rules. You can make your reducers whatever you
want. Combine them whichever way fits your use case. As long as you take a
state object and an action and return a new state object.`}</p>
    <p><inlineCode parentName="p">{`appReducer`}</inlineCode>{` will handle the constants and booleans and drive the metadata for
our animation. `}<inlineCode parentName="p">{`particlesReducer`}</inlineCode>{` will do the hard work of generating and
animating particles.`}</p>
    <h3 {...{
      "id": "driving-the-basics-with-appreducer"
    }}>{`Driving the basics with appReducer`}</h3>
    <p>{`Our `}<inlineCode parentName="p">{`appReducer`}</inlineCode>{` handles the boring actions with a big switch statement. These
are common in the Redux world. They help us decide what to do based on action
type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/reducers/index.js
function appReducer(state, action) {
  switch (action.type) {
    case "TICKER_STARTED":
      return Object.assign({}, state, {
        isTickerStarted: true,
        lastFrameTime: new Date(),
      })
    case "START_PARTICLES":
      return Object.assign({}, state, {
        generateParticles: true,
      })
    case "STOP_PARTICLES":
      return Object.assign({}, state, {
        generateParticles: false,
      })
    case "UPDATE_MOUSE_POS":
      return Object.assign({}, state, {
        mousePos: [action.x, action.y],
      })
    case "RESIZE_SCREEN":
      return Object.assign({}, state, {
        svgWidth: action.width,
        svgHeight: action.height,
      })
    default:
      return state
  }
}
`}</code></pre>
    <p>{`Gotta love that boilerplate :stuck_out_tongue:`}</p>
    <p>{`Even though we're only changing values of boolean flags and two-digit arrays,
`}<em parentName="p">{`we have to create a new state`}</em>{`. Redux relies on application state being
immutable.`}</p>
    <p>{`Well, JavaScript doesn't have real immutability. We pretend and make sure to
never change state without making a new copy first. There are libraries that
give you proper immutable data structures, but that's a whole different course.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`Object.assign({}, ...`}</inlineCode>{` to create a new empty object, fill it with the
current state, then overwrite specific values with new ones. This is fast
enough even with large state trees thanks to modern JavaScript engines.`}</p>
    <p>{`Note that when a reducer doesn't recognize an action, it has to return the same
state it received. Otherwise you end up wiping state. 😅`}</p>
    <p>{`So that's the boilerplatey state updates. Manages starting and stopping the
animation, flipping the particle generation switch, and resizing our viewport.`}</p>
    <p>{`The fun stuff happens in `}<inlineCode parentName="p">{`particleReducer`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "driving-particles-with-particlereducer"
    }}>{`Driving particles with particleReducer`}</h3>
    <p>{`Our particles live in an array. Each particle has an id, a position, and a
vector. That tells us where to draw the particle and how to move it to its
future position.`}</p>
    <p>{`On each tick of the animation we have to:`}</p>
    <ol>
      <li parentName="ol">{`Generate new particles`}</li>
      <li parentName="ol">{`Remove particles outside the viewport`}</li>
      <li parentName="ol">{`Move every particle by its vector`}</li>
    </ol>
    <p>{`We can do all that in one big reducer, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/reducers/index.js
function particlesReducer(state, action) {
  switch (action.type) {
    case "TIME_TICK":
      let {
          svgWidth,
          svgHeight,
          lastFrameTime,
          generateParticles,
          particlesPerTick,
          particleIndex,
          mousePos,
        } = state,
        newFrameTime = new Date(),
        multiplier = (newFrameTime - lastFrameTime) / (1000 / 60),
        newParticles = state.particles.slice(0)

      if (generateParticles) {
        for (let i = 0; i < particlesPerTick; i++) {
          let particle = {
            id: state.particleIndex + i,
            x: mousePos[0],
            y: mousePos[1],
          }

          particle.vector = [
            particle.id % 2 ? -randNormal() : randNormal(),
            -randNormal2() * 3.3,
          ]

          newParticles.unshift(particle)
        }

        particleIndex = particleIndex + particlesPerTick + 1
      }

      let movedParticles = newParticles
        .filter((p) => {
          return !(p.y > svgHeight || p.x < 0 || p.x > svgWidth)
        })
        .map((p) => {
          let [vx, vy] = p.vector
          p.x += vx * multiplier
          p.y += vy * multiplier
          p.vector[1] += Gravity * multiplier
          return p
        })

      return {
        particles: movedParticles,
        lastFrameTime: new Date(),
        particleIndex,
      }
    default:
      return {
        particles: state.particles,
        lastFrameTime: state.lastFrameTime,
        particleIndex: state.particleIndex,
      }
  }
}
`}</code></pre>
    <p>{`That's a lot of code, I know. Let me explain 😃`}</p>
    <p>{`The first part takes important values out of `}<inlineCode parentName="p">{`state`}</inlineCode>{`, calculates the dropped
frame multiplier, and makes a new copy of the particles array with `}<inlineCode parentName="p">{`.slice(0)`}</inlineCode>{`.
That was the fastest way I could find.`}</p>
    <p>{`Then we generate new particles.`}</p>
    <p>{`We loop through `}<inlineCode parentName="p">{`particlesPerTick`}</inlineCode>{` particles, create them at `}<inlineCode parentName="p">{`mousePos`}</inlineCode>{`
coordinates, and insert at the beginning of the array. In my tests that
performed best. Particles get random movement vectors.`}</p>
    <p>{`This randomness is a Redux faux pas. Reducers are supposed to be functionally
pure: produce the same result every time they are called with the same argument
values. Randomness is impure.`}</p>
    <p>{`We don't need our particle vectors to be deterministic, so I think this is
fine. Let's say our universe is stochastic instead 😄`}</p>
    <p>{`{aside} Stochastic means that our universe/physic simulation is governed by
probabilities. You can still model such a universe and reason about its
behavior. A lot of real world physics is stochastic in nature. {/aside}`}</p>
    <p>{`We now have an array full of old and new particles. We remove all out-of-bounds
particles with a `}<inlineCode parentName="p">{`filter`}</inlineCode>{`, then walk through what's left to move each particle
by its vector.`}</p>
    <p>{`To simulate gravity, we update vectors' vertical component using our `}<inlineCode parentName="p">{`Gravity`}</inlineCode>{`
constant. That makes particles fall down faster and faster creating a nice
parabola.`}</p>
    <p>{`Our reducer is done. Our particle generator works. Our thing animates smoothly.
`}{`\\`}{`o/`}</p>
    <h2 {...{
      "id": "what-we-learned"
    }}>{`What we learned`}</h2>
    <p>{`Building a particle generator in React and Redux, we made three important
discoveries:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Redux is much faster than you'd think`}</strong>{`. Creating a new copy of the state
tree on each animation loop sounds crazy, but it works. Most of our code
creates shallow copies, which explains the speed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Adding to JavaScript arrays is slow`}</strong>{`. Once we hit about 300 particles,
adding new ones becomes slow. Stop adding particles and you get smooth
animation. This indicates that something about creating particles is slow:
either adding to the array, or creating React component instances, or
creating SVG DOM nodes.`}</li>
      <li parentName="ol"><strong parentName="li">{`SVG is also slow`}</strong>{`. To test the above hypothesis, I made the generator
create 3000 particles on first click. The animation speed is `}<em parentName="li">{`terrible`}</em>{` at
first and becomes okayish at around 1000 particles. This suggests that
making shallow copies of big arrays and moving existing SVG nodes around is
faster than adding new DOM nodes and array elements.
`}<a parentName="li" {...{
          "href": "https://i.imgur.com/ug478Me.gif"
        }}>{`Here's a gif`}</a></li>
    </ol>
    <hr></hr>
    <p>{`There you go: Animating with React, Redux, and D3. Kind of a new superpower
😉`}</p>
    <p>{`Here's the recap:`}</p>
    <ul>
      <li parentName="ul">{`React handles rendering`}</li>
      <li parentName="ul">{`D3 calculates stuff, detects mouse positions`}</li>
      <li parentName="ul">{`Redux handles state`}</li>
      <li parentName="ul">{`element coordinates are state`}</li>
      <li parentName="ul">{`change coordinates on every `}<inlineCode parentName="li">{`requestAnimationFrame`}</inlineCode></li>
      <li parentName="ul">{`animation!`}</li>
    </ul>
    <p>{`Now let's render to canvas and push this sucker to 20,000 smoothly animated
elements. Even on a mobile phone.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      